export const getQBLoginQuery = (email, nextToken) => {
  const myNextToken = nextToken ? '"' + nextToken + '"' : null;
  return {
    query: `query MyQuery {
        listCompanys(limit: 500, pk:"${email}", nextToken: ${myNextToken}) {
          nextToken
          items {
            pk
            realmId
            companyName
            refreshToken
            accountingMethod
            nickName
          }
        }
      }`
  };
};


export const getQBLoginByCompanyQuery = (email, realmId) => ({
  query: `query MyQuery {
    getCompany(pk: "${email}", realmId: "${realmId}") {
      pk
      companyName
    }
  }`
});

export const getAccountQuery = (email) => ({
  query: `query MyQuery {
    getAccount(pk: "${email}") {
      tier
    }
  }`
});

export const updateQBLoginQuery = (email, realmId, idToken, accessToken, refreshToken) => ({
  query: `mutation MyMutation {
    updateCompany(
      input: {
        pk:"${email}", 
        realmId:"${realmId}",
        idToken: "${idToken}",
        accessToken: "${accessToken}",
        refreshToken: "${refreshToken}"
      }) {
      pk
      realmId
      updatedAt
    }
  }`
});

export const updateQBCompanySettings = (email, realmId, companyName, settings) => ({
  query: `mutation MyMutation {
     updateCompany(
       input: {
         pk: "${email}",
         realmId: "${realmId}", 
         companyName: "${companyName}", 
         accountingMethod: "${settings.accMethod}",
         nickName: "${settings.nickName}" 
        }) {
      pk
      companyName
      accountingMethod
    }
  }`
});


export const createQBLoginQuery = (company) => ({
  query: `mutation MyMutation {
    createCompany(
      input: {
        pk: "${company.owner}",
        realmId: "${company.realmId}",

        accountingMethod: "${company.accountingMethod}",
        accessToken: "${company.accessToken}",
        companyName: "${company.companyName}",
        nickName: "${company.nickName}",
        idToken: "${company.idToken}",
        owner: "${company.owner}",
        refreshToken: "${company.refreshToken}",
        srcCompany: "${company.srcCompany}",
        srcId: "${company.srcId}"}) {
      pk
      realmId
      createdAt
    }
  }`
});

export const deleteQBLoginQuery = (email, realmId) => ({
  query: `mutation MyMutation {
    deleteCompany(input: {pk: "${email}", realmId: "${realmId}"}) {
      pk
      realmId
      companyName
    }
  }`,
});

export const createAccountQuery = (email, lastActiveDate) => ({
  query: `mutation MyMutation {
    createAccount(
      input: {
        owner: "${email}",
        pk: "${email}",
        tier: "trial",
        email: "${email}",
        accountingSoftware: "Intuit",
        lastActive: "${lastActiveDate}",
        freeTrialStartedAt: "${lastActiveDate}"
      }) {
      createdAt
      email
      tier
    }
  }`
});


export const updateAccountQuery = (email, tier) => ({
  query: `mutation MyMutation {
    updateAccount(
      input: {
        pk: "${email}",
        email: "${email}",
        tier: "${tier}",
        owner: "${email}"
      }) {
      email
      tier
      updatedAt
    }
  }`
});

export const updateDiscountQuery = (email, discount) => ({
  query: `mutation MyMutation {
    updateAccount(
      input: {
        pk: "${email}",
        discount: "${discount}"
      }) {
      email
    }
  }`
});

export const updateAccountActivityQuery = (email, lastActiveDate) => ({
  query: `mutation MyMutation {
    updateAccount(
      input: {
        pk: "${email}",
        lastActive: "${lastActiveDate}"
      }) {
      email
      lastActive
    }
  }`
});