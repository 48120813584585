import { AWSWrapper } from "../aws";
import { loadStripe } from '@stripe/stripe-js';
import { StripeConfig } from '../globalVariables';

// Modified from https://gist.github.com/peterherrmann/2700284
/**
* Invokes a function, performing up to 3 retries with exponential backoff.
* Retries with delays of approximately 50, 100, then 200 milliseconds for a total of 
* about 350 milliseconds before it gives up and rethrows the last error. 
* See: https://developers.google.com/google-apps/documents-list/#implementing_exponential_backoff 
*
* @param func The anonymous or named function to call.
* @param args args for func.
* @return {*} The value returned by the called function.
*/
export async function callWithRetries(func, ...args) {
  let count = 0;
  while (true) {
    try {
      return func(...args);
    } catch (e) {
      if (count === 3) {
        throw e;
      }
      setTimeout(() => {}, (Math.pow(2, count) * 50) + (Math.round(Math.random() * 50)));
      count += 1;
    }
  }
}

export const getUserEmail = async (token) => {
  await AWSWrapper.credentialsRefresh(token);
  const result = await AWSWrapper.getUserAttributes();
  if (result.success === false) {
    return null;
  }
  for (let prop of result.data) {
    if (prop.Name === 'email') {
      return prop.Value;
    }
  }
  return null;
}

const goToCheckout = async (email, priceId) => {
  const rootUrl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
  const BASEURL = "https://qo2rolglsd.execute-api.us-east-1.amazonaws.com/default/funcHandlePayment-dev?";
  let rootURL = email === "geniussheets@gmail.com" ? `${rootUrl}&test=true` : rootUrl;
  const url = `${BASEURL}email=${email}&priceId=${priceId}&rootUrl=${rootURL}&getSessionId=true`;

  const lmref = window.lmFinished();

  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ lmref })
  })
    .then((res) => res.json())
    .then(data => data)
    .catch((err) => console.log(err));
}

export const handleStripe = async ( tier, isMonthly = true ) => {
  const stripePromise = loadStripe(
    StripeConfig.PublishableKey
  );
  let userEmail = null;
  const token = localStorage.getItem('GStoken');
  if (token) {
    userEmail = await getUserEmail(token);
  }
  if (!token || !userEmail) {
    throw 'Signup first!';
  }
  if (tier === 'free') {
   return;
  }
  if (tier === 'enterprise') {
    window.open('mailto:trevor@geniussheets.com?Subject=Interested in using Genius Sheets');
    return;
  }
  try {
    let price_id =  isMonthly ? StripeConfig[tier].monthly : StripeConfig[tier].yearly;
    const checkout_session_id = await goToCheckout(userEmail, price_id);
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: checkout_session_id.sessionId,
    });
    if (error) {
      throw error;
    }
  } catch (error) {
    throw 'Try again later or contact admin';
  }
} 