import { AWSConfig } from "./globalVariables";
import AWS from "aws-sdk";
import Axios from "axios";
import {
  createAccountRequest,
  createQBLoginMutation,
  deleteQBLoginMutation,
  getQBLoginByCompanyRequest,
  getQBLoginRequest,
  updateQBLoginMutation,
  updateAccountMutation,
  updateCompanySettingsMutation,
  createIntuitJobQueue,
  updateAccountActivity,
  manualCompanyRefresh,
  sendUserLogToAWS,
  getAccount,
  createQBTokens,
  createQBUri,
  revokeQBAccess,
  updateAccountDiscountMutation,
  triggerLoopEmailEvent
} from "./lib/awsRequests";
import { callWithRetries } from "./lib/util";

const AmazonCognitoIdentity = require("amazon-cognito-identity-js");
const OAuthClient = require("intuit-oauth");

/**
 * Parse identification JWT token
 * @param token
 */
function parseIdToken(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

/**
 * Gets the AWS configuration
 */
function getConfiguration() {
  return {
    poolData: {
      UserPoolId: AWSConfig.AWSUserPoolId,
      ClientId: AWSConfig.AWSClientId,
    },
    userTable: AWSConfig.AWSUserTable,
    region: AWSConfig.AWSRegion,
    userPoolId: AWSConfig.AWSUserPoolId,
    identityPoolId: AWSConfig.AWSIdentityPoolId,
  };
}

/**
 * AWS result
 */
function createResult(err, result) {
  if (err) {
    return {
      success: false,
      error: err,
    };
  } else {
    return {
      success: true,
      data: result,
    };
  }
}

/**
 * Gets current user
 * @param poolData
 */
function getCurrentUser(poolData) {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  return userPool.getCurrentUser();
}

/**
 * Login
 * @param username
 * @param password
 */
function login(username, password) {
  return new Promise((resolve, reject) => {
    try {
      const configuration = getConfiguration();

      const authenticationData = {
        Username: username,
        Password: password,
      };

      const authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

      const userPool = new AmazonCognitoIdentity.CognitoUserPool(
        configuration.poolData
      );
      const userData = {
        Username: username,
        Pool: userPool,
      };

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          const accessToken = result.getAccessToken().getJwtToken();
          const idToken = result.getIdToken().getJwtToken();
          const idTokenJWT = parseIdToken(idToken);

          /*
                    AWS.config.region = configuration.region;
                    const name = 'cognito-idp.' + AWS.config.region + '.amazonaws.com/' + configuration.userPoolId;
                    const logins = {};
                    logins[name] = result.getIdToken().getJwtToken();


                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: configuration.identityPoolId, // your identity pool id here
                        Logins: logins,
                    });

                    //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                    AWS.config.credentials.refresh(function (error) {
                        if (error) {
                            resolve(createResult(error.message || JSON.stringify(error), null));
                        } else {
                            resolve(createResult(null, {
                                access_token: accessToken,
                                id_token: idToken
                            }));
                        }
                    });*/

          resolve(
            createResult(null, {
              access_token: accessToken,
              id_token: idToken,
            })
          );
        },

        onFailure: function (err) {
          resolve(createResult(err.message, null));
        },
      });
    } catch (err) {
      resolve(createResult(err.message, null));
    }
  });
}

/**
 * Refresh credentials for AWS SDK
 * @param idToken
 */
function credentialsRefresh(idToken) {
  return new Promise((resolve, reject) => {
    const configuration = getConfiguration();

    AWS.config.region = configuration.region;
    const name =
      "cognito-idp." +
      AWS.config.region +
      ".amazonaws.com/" +
      configuration.userPoolId;
    const logins = {};
    logins[name] = idToken;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: configuration.identityPoolId, // your identity pool id here
      Logins: logins,
    });

    //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
    AWS.config.credentials.refresh(function (error) {
      if (error) {
        resolve(createResult(error.message || JSON.stringify(error), null));
      } else {
        resolve(createResult(null, { success: true }));
      }
    });
  });
}

/**
 * Gets user AWS Cognito attributes
 */
function getUserAttributes() {
  return new Promise((resolve, reject) => {
    const configuration = getConfiguration();
    const poolData = configuration.poolData;

    const cognitoUser = getCurrentUser(poolData);
    cognitoUser.getSession(function (err, session) {
      if (err) {
        console.log(err.message || JSON.stringify(err));
        resolve(createResult(err, null));
      }

      cognitoUser.getUserAttributes(function (err, result) {
        if (err) {
          console.log(err.message || JSON.stringify(err));
          resolve(createResult(err, null));
        } else {
          resolve(createResult(null, result));
        }
      });
    });
  });
}

/**
 * Gets one-page saved QB login from DynamoDB Company table
 * @param email
 * @param nextToken
 */
async function getQBLoginOnePage(email, nextToken) {
  try {
    const result = await callWithRetries(getQBLoginRequest,email, nextToken);
    if (result && result.items)
      return createResult(null, result);
    else return createResult(`QB record for ${email} not found`, null);
  } catch (err) {
    return createResult(err.message, null);
  }
}

/**
 * Gets saved QB login from DynamoDB Company table
 * @param email
 */
async function getQBLogin(email) {
  const companies = [];
  let nextToken = null;
  while (true) {
    const result = await getQBLoginOnePage(email, nextToken);
    if (result.success) {
      companies.push(...result.data.items);
      nextToken = result.data.nextToken;
      if (!nextToken) {
        // if nextToken is null, then it's the last page.
        break;
      }
    } else {
      return result;
    }
  }
  return createResult(null, companies);
};

/**
 * Gets saved QB login from DynamoDB Company table
 * @param email
 * @param realmId
 */
async function getQBLoginByCompany(email, realmId) {
  try {
    const result = await callWithRetries(getQBLoginByCompanyRequest,email, realmId);
    if (result && result.pk)
      return createResult(null, result);
    else return createResult(`QB record for ${email}:${realmId} not found`, null);
  } catch (err) {
    return createResult(err.message, null);
  }
}

/**
 * Update QB login to DynamoDB Company table
 * @param email
 * @param realmId
 * @param idToken
 * @param accessToken
 * @param refreshToken
 */
async function updateQBLogin(email, realmId, idToken, accessToken, refreshToken) {
  try {
    const result = await callWithRetries(updateQBLoginMutation, 
      email,
      realmId,
      idToken,
      accessToken,
      refreshToken);
    return createResult(null, result);
  } catch (err) {
    await sendUserLogToAWS(realmId,err.message);
    return createResult(err.message, null);
  }
}

/**
 * Update a company's accounting method to DynamoDB Company table
 * @param email
 * @param realmId
 * @param companyName
 * @param accessToken
 * @param accMethod
 */
async function updateCompanySettings(email, realmId, companyName, settings){
  try {
    const result = await callWithRetries(updateCompanySettingsMutation,
      email,
      realmId,
      companyName,
      settings);
    return createResult(null, result); 
  } catch (error) {
    await sendUserLogToAWS(realmId, error.message);
    return createResult(error.message, null);
  }
}

/**
 * Creates QB login to DynamoDB Company table
 * @param company
 */
async function createQBLogin(company) {
  try {
    const result = await callWithRetries(createQBLoginMutation, company)
    await createIntuitJobQueue(company.realmId);
    await updateAccountActivity(company.owner)
    return createResult(null, result);
  } catch (err) {
    await sendUserLogToAWS(company.realmId, err.message);
    return createResult(err.message, null);
  }
}

/**
 * Send request to do full company data refresh
 * @param realmId
 */
 async function companyDataRefresh(realmId) {
  try {
    const result = await manualCompanyRefresh(realmId);
    return createResult(null, result);
  } catch (err) {
    return createResult(err.message, null);
  }
}

/**
 * Creates account to DynamoDB Account table
 * @param email
 */
async function createAccount(email) {
  try {
    const result = await createAccountRequest(email);
    return createResult(null, result);
  } catch (err) {
    return createResult(err.message, null);
  }
}

/**
 * Get account information from DynamoDB Account table
 * @param email
 */
async function getAccountInformation(email) {
  try {
    const result = await getAccount(email);
    return createResult(null, result);
  } catch (err) {
    return createResult(err.message, null);
  }
}

/**
 * Update account to DynamoDB Account table
 * @param email
 * @param tier
 */
async function updateAccount(email, tier) {
  try {
    const result = await updateAccountMutation(email, tier);
    return createResult(null, result);
  } catch (err) {
    return createResult(err.message, null);
  }
}

async function revokeAccess(email, realmId) {
  try {
    await revokeQBAccess(realmId);
    await deleteQBLoginMutation(email, realmId);
    return createResult(null, { success: "" });
  } catch (error) {
    return createResult("Error disconnecting company. Please try again.", null);
  }
}

async function triggerLoopEmail(eventName) {
  try {
    await triggerLoopEmailEvent(eventName);
  } catch (error) {
    return createResult("Error triggering Loop emails", null);
  }
}

function logout() {
  return new Promise((resolve, reject) => {
    const configuration = getConfiguration();
    const poolData = configuration.poolData;

    const cognitoUser = getCurrentUser(poolData);
    cognitoUser.getSession(function (err, session) {
      if (err) {
        resolve(createResult(err.message || JSON.stringify(err), null));
        return;
      }
      session.user = null;
    });

    if (cognitoUser != null)
      cognitoUser.signOut(() => {
        resolve(createResult(null, { success: true }));
      });
  });
}

function signUp(user) {
  return new Promise((resolve, reject) => {
    const configuration = getConfiguration();
    const poolData = configuration.poolData;

    const attributes = [];
    const emailData = {
      Name: "email",
      Value: user.email,
    };
    const nameData = {
      Name: "name",
      Value: user.name,
    };

    attributes.push(new AmazonCognitoIdentity.CognitoUserAttribute(emailData));
    attributes.push(new AmazonCognitoIdentity.CognitoUserAttribute(nameData));

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(
      configuration.poolData
    );
    userPool.signUp(
      user.username,
      user.password,
      attributes,
      null,
      (err, result) => {
        if (err) {
          resolve(createResult(err.message || JSON.stringify(err), null));
          return;
        }
        resolve(createResult(null, { success: true }));
      }
    );
  });
}

function confirmUser(email, code) {
  return new Promise((resolve, reject) => {
    const configuration = getConfiguration();
    const poolData = configuration.poolData;

    const userData = {
      Username: email,
      Pool: new AmazonCognitoIdentity.CognitoUserPool(configuration.poolData),
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        console.log(err);
        resolve(createResult(err.message || JSON.stringify(err), null));
        return;
      }
      resolve(createResult(null, { success: true }));
    });
  });
}

function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    const configuration = getConfiguration();
    const poolData = configuration.poolData;

    const userData = {
      Username: email,
      Pool: new AmazonCognitoIdentity.CognitoUserPool(configuration.poolData),
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        resolve(createResult(null, result));
      },
      onFailure: function (err) {
        console.log(err);
        resolve(createResult(err.message || JSON.stringify(err), null));
      },
    });
  });
}

function confirmPassword(email, code, password) {
  return new Promise((resolve, reject) => {
    const configuration = getConfiguration();
    const poolData = configuration.poolData;

    const userData = {
      Username: email,
      Pool: new AmazonCognitoIdentity.CognitoUserPool(configuration.poolData),
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve(createResult(null, { success: true }));
      },
      onFailure: function (err) {
        console.log(err);
        resolve(createResult(err.message || JSON.stringify(err), null));
      },
    });
  });
}

async function QBlogin() {
  try {
    const response = await createQBUri();
    if (!response.oauth_uri) {
      alert('Error creating QuickBooks URI. Please try again.');
      return;
    }
    window.location.href = response.oauth_uri;
  } catch (error) {
    return createResult(error.message || JSON.stringify(error), null);
  }
}

async function createToken(code, realmId) {
  try {
    const response = await createQBTokens(code, realmId);
    if (!response.auth_tokens) {
      return createResult("Error creating company tokens. Please try again.", null);
    }
    const tokens = response.auth_tokens;
    return createResult(null, {
      id_token: tokens.id_token,
      refresh_token: tokens.refresh_token,
      access_token: tokens.access_token,
      realmId: tokens.realmId,
    });
  } catch (error) {
    return createResult(error.message || JSON.stringify(error), null);
  }
}

function getCompanyProfile(accessToken, realmId) {
  return new Promise((resolve, reject) => {
    const url = `https://geniussheets-excel.herokuapp.com/v3/company/${realmId}/companyinfo/${realmId}`;

    Axios.get(url, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      },
    })
      .then((res) => {
        const profile = res.data;
        resolve(createResult(null, profile));
      })
      .catch((err) => {
        resolve(createResult(err.message || JSON.stringify(err), null));
      });
  });
}

async function storeDiscount(email, discount) {
  try {
    const result = await updateAccountDiscountMutation(email, discount);
    return createResult(null, result);
  } catch (err) {
    return createResult(err.message, null);
  }
}

export const AWSWrapper = {
  parseIdToken,
  login,
  logout,
  credentialsRefresh,
  getUserAttributes,
  getQBLogin,
  getQBLoginByCompany,
  updateQBLogin,
  updateCompanySettings,
  createQBLogin,
  createAccount,
  updateAccount,
  signUp,
  confirmUser,
  forgotPassword,
  confirmPassword,
  QBlogin,
  createToken,
  getCompanyProfile,
  revokeAccess,
  companyDataRefresh,
  getAccountInformation,
  storeDiscount,
  triggerLoopEmail
};
