import React from "react";
import "./Footer.css";
import "../../css/RowsAndColumns.css";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import { LazyLoadImage } from "react-lazy-load-image-component";

const Left = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <LazyLoadImage
            width="87px"
            src={require("../../assets/images/logos/logo_min.png")}
          />
          <p style={{ fontWeight: "600" }}>ABOUT</p>
          <p style={{ color: "gainsboro", padding: "0px 20px 10px 20px" }}>
            Genius Sheets was founded to address the needs of dynamic
            organizations that understand the power of spreadsheets but want
            more flexibility, automation, and controls. Genius Sheets brings you
            the benefits of large enterprise software without the long
            onboarding and complicated software.
          </p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <p style={{ paddingTop: "2rem" }}>
            For more information contact:
            <br />{" "}
            <a
              className="FooterPage-email"
              href="mailto: info@geniussheets.com"
            >
              info@geniussheets.com
            </a>
          </p>
        </Grid>
      </Grid>
    </Container>
  );
};

const Right = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Link style={{ color: "white" }} to="/privacy-policy">
            Privacy Policy
          </Link>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Link style={{ color: "white" }} to="/tou">
            Terms of Use
          </Link>
        </Grid>
        <Grid item xs={12} sm={3}>
          &copy; 2020 Genius Sheets Inc
        </Grid>
      </Grid>
    </Container>
  );
};

export default function Footer() {
  return (
    <div className="footer_d ">
      <Left />
      <Right />
    </div>
  );
}
