import React from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import Container from '@material-ui/core/Container';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Link } from 'react-router-dom';

const PaymentFail = () => {
  return (
    <>
      <Header />
      <main
        style={{
          padding: '15vh 0 20vh 0',
          minHeight: '100vh',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container maxWidth="md">
          <img src="imgs/payment_fail.png" style={{ maxWidth: '450px' }} />
          <h2>Something went Wrong</h2>
          <p>
            If payment has been made from your side then please contact admin.
          </p>

          <Link to="/">Home </Link>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default PaymentFail;
