import axios from "axios";
import moment from 'moment';

import {
  createAccountQuery,
  createQBLoginQuery,
  deleteQBLoginQuery,
  getQBLoginByCompanyQuery,
  getQBLoginQuery,
  updateQBLoginQuery,
  updateAccountQuery,
  updateAccountActivityQuery,
  updateQBCompanySettings,
  getAccountQuery,
  updateDiscountQuery
} from "./queries";

const url =
  "https://3zlkxzydanbwvemxerkgcm3nzu.appsync-api.us-east-1.amazonaws.com/graphql";

const FETCH_DATA_REST_API = "https://0ty5hxkwcb.execute-api.us-east-1.amazonaws.com/default/funcHandleFetchDataRequest-dev";

const authConfig = () => ({
  headers: {
    "content-type": "application/json",
    Authorization: localStorage.getItem("GStoken"),
  },
});

const config = {
  headers: {
    "content-type": "application/json",
  },
};

export const createIntuitJobQueue = (realmId) => {
  return axios
    .post(`${FETCH_DATA_REST_API}?realmId=${realmId}&createQueue=true&fetchData=true`, {}, authConfig())
    .then((res) => {
    });
}

export const sendUserLogToAWS = async (realmId, logs) => {
  return axios
    .post(`${FETCH_DATA_REST_API}?realmId=${realmId}&userLogs=true`, {'userLogs':JSON.stringify(logs)}, authConfig())
    .then((res) => {
    });
}

export const createQBTokens = async (authCode, realmId) => {
  return axios
    .post(`${FETCH_DATA_REST_API}?target=Intuit&realmId=${realmId}&createTokens=true&authCode=${authCode}`, {}, authConfig())
    .then((res) => {
      return res.data;
    });
}

export const createQBUri = async () => {
  return axios
    .post(`${FETCH_DATA_REST_API}?oauthUri=true&target=Intuit`, {}, authConfig())
    .then((res) => {
      return res.data;
    });
}

export const revokeQBAccess = async (realmId) => {
  return axios
    .post(`${FETCH_DATA_REST_API}?target=Intuit&realmId=${realmId}&revoke=true`, {}, authConfig())
    .then((res) => {
      return res.data;
    });
}

export const triggerLoopEmailEvent = (eventName) => {
  return axios
    .post(`${FETCH_DATA_REST_API}?&triggerLoopEmails=true&eventName=${eventName}`, {}, authConfig())
    .then((res) => {
      return res.data;
    })
}

export const sendAwsLogin = () => {
  return axios
    .post(`${FETCH_DATA_REST_API}?login=web`, {}, authConfig())
    .then((res) => {
    });
};

export const manualCompanyRefresh = (realmId) => {
  return axios
    .post(`${FETCH_DATA_REST_API}?realmId=${realmId}&fetchData=true`, {}, authConfig())
    .then((res) => {
    
    });
};

export const getQBLoginRequest = (email, nextToken) => {
  return axios
    .post(url, { ...getQBLoginQuery(email, nextToken) }, authConfig())
    .then((res) => {
      return { 'items': res.data.data.listCompanys.items, 'nextToken': res.data.data.listCompanys.nextToken };
    });
};

export const getQBLoginByCompanyRequest = (email, realmId) => {
  return axios
    .post(
      url,
      { ...getQBLoginByCompanyQuery(email, realmId) },
      authConfig()
    )
    .then((res) => {
      return res.data.data.getCompany;
    });
};

export const updateQBLoginMutation = (email, realmId, idToken, accessToken, refreshToken) => {
  return axios
    .post(
      url,
      { ...updateQBLoginQuery(email, realmId, idToken, accessToken, refreshToken) },
      authConfig()
    )
    .then((res) => {
      return res.data;
    });
};

export const updateCompanySettingsMutation = (email, realmId, companyName, settings) => {
  
  return axios
    .post(
      url,
      { ...updateQBCompanySettings(email, realmId, companyName, settings) },
      authConfig()
    )
    .then((res) => {
      return res.data;
    });
}

export const createQBLoginMutation = (company) => {
  return axios
    .post(url, { ...createQBLoginQuery(company) }, authConfig())
    .then((res) => {
      return res.data;
    });
};

export const deleteQBLoginMutation = (email, realmId) => {
  return axios
    .post(url, { ...deleteQBLoginQuery(email, realmId) }, authConfig())
    .then((res) => {
      return res.data;
    });
};

export const createAccountRequest = (email) => {
  const lastActiveDate = moment.utc().format();
  return axios
    .post(url, { ...createAccountQuery(email, lastActiveDate) }, authConfig())
    .then((res) => {
      return res.data;
    });
};

export const updateAccountMutation = (email, tier) => {
  return axios
    .post(url, { ...updateAccountQuery(email, tier) }, authConfig())
    .then((res) => {
      return res.data;
    });
};

export const updateAccountDiscountMutation = (email, discount) => {
  return axios
    .post(url, { ...updateDiscountQuery(email, discount) }, authConfig())
    .then((res) => {
      return res.data;
    });
}

export const getAccount = (email) => {
  return axios
    .post(url, { ...getAccountQuery(email) }, authConfig())
    .then((res) => {
      return res.data;
    });
};

export const updateAccountActivity = (email) => {
  const lastActiveDate = moment.utc().format();
  return axios
    .post(url, { ...updateAccountActivityQuery(email, lastActiveDate) }, authConfig())
    .then((res) => {
      return res.data;
    });
}
