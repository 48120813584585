import React, { useState } from 'react';
import './Header.css';
import LoginButton from '../Buttons/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AWSWrapper } from '../../aws';
import Container from '@material-ui/core/Container';

function Header() {
  const [showDropdown, setShowDropdown] = useState(false);  
  const [showHamburger, setShowHamburger] = useState(false);  
  
  let history = useHistory();
  
  function handleDropdownOpen() {
    setShowDropdown((prev) => !prev);
  }
  
  function handleShowHamburger() {
    setShowHamburger((prev) => !prev);
  }

  const LogoutHandle = async (e) => {
    e.preventDefault();
  
    localStorage.removeItem('GStoken');
    const result = await AWSWrapper.logout();
    history.push('/login');
  };
  
  function navigateToPricing () {
    window.location.href = 'https://www.geniussheets.com/pricing';
  }
  function navigateToHome () {
    window.location.href = 'https://www.geniussheets.com';
  }
  
  function navigateToFinanceTeams () {
    window.location.href = 'https://www.geniussheets.com/for-finance-teams';
  }
  function navigateToAccountants () {
    window.location.href = 'https://www.geniussheets.com/for-accountants2';
  }
  
  function navigateToConsoloidatedReporting () {
    window.location.href = 'https://www.geniussheets.com/consolidated-reporting';
  }
  
  function HeaderDrawer() {
    return (
      <div className='drawer'>
        <List className="header_navup">
          <ListItem>
            <ListItemText>Genius Sheets</ListItemText>
          </ListItem>
        </List>
        <Divider />
          <List>
            <ListItem button onClick={navigateToHome}>
              <ListItemText>Home</ListItemText>
            </ListItem>
          
            <ListItem button onClick={navigateToPricing}>
              <ListItemText>Pricing</ListItemText>
            </ListItem>
          {localStorage.getItem('GStoken') ? (
            <ListItem button onClick={LogoutHandle}>
              <ListItemText>Logout</ListItemText>
            </ListItem>
          ): null}
        </List>
  
        <Divider />
        <List className="Header_bottomDrawer">
          <ListItem button href="/">
            <LazyLoadImage
              className="Header_bottomDrawerImg"
              alt="UnivyFinance"
              src={require('../../assets/images/logos/GS.png')}
            />
          </ListItem>
        </List>
      </div>
    );
  }
  
  return (
    <>
      <Container className="header-container" maxWidth="lg" align="center" >
        <a href="https://www.geniussheets.com" className="header-logo-link"><img src={require('../../assets/images/graphics/hpmaArtboard-2-copy-2.png')}
        alt="" className="header-logo logo-padding"/></a>
        <ul className="header-nav-menu-list" >
          <li className="header-nav-list-item middle">
            <a onClick={navigateToHome} className="header-nav-link w-nav-link">Home</a>
          </li>
          <li className="header-nav-list-item middle">
            <div data-hover="true" data-delay="0" data-w-id="2374781b-3a62-48bb-7a03-a8464b70d7ca" className="dropdown-wrapper w-dropdown">
              <div onClick={()=> handleDropdownOpen()} className="header-nav-link dropdown-header-link w-dropdown-toggle">
                <div >Use Cases</div>
                <div className="line-rounded-icon dropdown-arrow"></div>
              </div>
              <nav className={showDropdown? "dropdown-column-wrapper w-dropdown-list w--open position-absolute": "dropdown-column-wrapper w-dropdown-list hidde-dropdown"}>
                <div className="card dropdown-nav">
                  <div className="menu-wrapper---main">
                    <div className="menu-wrapper">
                      <div className="nav-content">
                        <ul role="list" className="nav-menu-list-wrapper">
                          <li onClick={navigateToFinanceTeams}className="nav-menu-list-item">
                            <a className="nav-link">For Finance Teams</a>
                          </li>
                          <li onClick={navigateToAccountants}className="nav-menu-list-item">
                            <a aria-current="page" className="nav-link w--current">For Accountants</a>
                          </li>
                          <li onClick={navigateToConsoloidatedReporting}className="nav-menu-list-item">
                            <a className="nav-link">Consolidated Reporting</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </li>
          <li onClick={navigateToPricing} className="header-nav-list-item middle">
            <a className="header-nav-link w-nav-link">Pricing</a>
          </li>
        </ul>
        <div className="header-right-side">
          <a onClick={LogoutHandle} className="header-nav-link hidden-on-tablet w-inline-block logout">
            <div className="line-rounded-icon link-icon-left color-accent-1"></div>
            <div className="link-text">Logout</div>
          </a>
          <div onClick={handleShowHamburger}className="hamburger-menu-btn btn-primary w-nav-button">
            <div className="hamburger-menu-wrapper">
              <div className="hamburger-menu-bar top"></div>
              <div className="hamburger-menu-bar bottom"></div>
            </div>
            <div className="position-absolute btn-primary-gradient"></div>
          </div>
        </div>
        <div>
        </div>
      </Container>
      {showHamburger && HeaderDrawer()}
    </>
  );
}

export default Header;
