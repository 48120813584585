export const AWSConfig = {
  AWSUserPoolId: "us-east-1_nTh01ka9E",
  AWSClientId: "238bfv70j1m60pflebbtfnaqr1",
  AWSIdentityPoolId: "us-east-1:0a9a98d5-dc3c-4485-9e81-b4ff301cfa3c",
  AWSRegion: "us-east-1",
  AWSUserTable: "User-ytsqa2vngvb2xavxkxo3qx25ou-dev",
};


// Stripe test mode
// export const StripeConfig = {
//   PublishableKey: "pk_test_51JBi0ZIU246eZuS2KufbdzHDlgOLcGe6CRb5o6r1ZOLatJeX4sWuzcb1A2xrVogBOfjOQz79rgK8Vwyt0hwzT4BK00OqPdCmBg",
//   CfoPriceIdMonthly: "price_1KMuAkIU246eZuS2xIICZNOV",
// };

// Stripe live mode
export const StripeConfig = {
  PublishableKey: "pk_live_51JBi0ZIU246eZuS2ndIm7xGYwcFr6LRwd9N03lv6nLP5AxbCMazIYr85PefbgEemIF7ZNg8LoOMZeVgSER1AMsfg00R825xsFi",
  "cfo": {
    monthly: "price_1KQeGIIU246eZuS25asKg8oy",
    yearly: "price_1L3Mg6IU246eZuS2R5HtKS9P",
  },
  "business": {
    monthly: "price_1LueT7IU246eZuS2Cdj4EGyG",
    yearly: "price_1LxwKcIU246eZuS28YoLIOCZ"
  },
  "business+": {
    monthly: "price_1LxveNIU246eZuS2ObJ1hrsU",
    yearly: "price_1LxveeIU246eZuS2duTG0aHI"
  }
  
};