import React from 'react';
import './Loading.css';

function Loading(){
    return (
        <div className="Loading_body">
            <img width="20%" src="https://media3.giphy.com/media/26DN2iTaW5mmmJtug/source.gif" alt="loading"/>
        </div>
    );
}

export default Loading;